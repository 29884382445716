.App {
  text-align: center;
}

.content {
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;
}

.hidden {
  display: none;
}
